
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class StockDetails extends Vue {
  private reports = [
    { viewName: 'Stock/Reports/BalanceAdjustments', name: 'Stock Balance Adjustments' },
    { viewName: 'Stock/Reports/Deliveries', name: 'Stock Deliveries' },
    { viewName: 'Stock/Reports/Values', name: 'Stock Values' },
    { viewName: 'Stock/Reports/ValueItems', name: 'Stock ValueItems' },
    { viewName: 'Stock/Reports/InventoriesWithDiffList', name: 'Stock Inventories with Difference List' },
  ]

  private navigateToReport(viewName: string) {
    this.$router.push({
      name: viewName,
    })
  }
}
